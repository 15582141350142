import React from "react"
import { FormattedMessage, injectIntl } from "gatsby-plugin-react-intl"
import Seo from "../components/Seo"

// Component used when gatsby-plugin-react-intl redirects to the browser detected lang.
// See gatsby-config.js: gatsby-plugin-react-intl.redirect: true
const Redirect = ({ intl }) => (
  <>
    <Seo title={`${intl.formatMessage({ id: "title" })}`} />
    <p>
      <FormattedMessage id="redirect.p" defaultMessage="Redirecting to your favorite language..." />
    </p>
  </>
)

export default injectIntl(Redirect)
