module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"simplefoncier.ca","short_name":"sfca","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/logo.png","lang":"en","localize":[{"start_url":"/en/","lang":"en","description":"Accessible public records to your reach."},{"start_url":"/fr/","lang":"fr","description":"Dossier publics à votre portée."}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"dc5b402b3b0d0166a6006f88ef6e2c15"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/home/vsts/work/1/s/src/intl","languages":["en","fr"],"defaultLanguage":"en","redirect":false,"redirectComponent":"/home/vsts/work/1/s/src/components/Redirect.js"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
